import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useLocalStorageValue as useLocalStorage } from '@react-hookz/web';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import TweetCard from 'react-tweet-card';

import DefaultAvatar from '../assets/images/default-avatar.png';
import { DB } from '../constants';
import { convertVideoInputToAudio } from '../lib/ffmpeg';
import { transcriptToTweets, whisperUpload } from '../lib/openai';

const GradientTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  background: 'linear-gradient(45deg, #2b6ec1, #b41343)',
  backgroundColor: theme.palette.text.primary,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

export default function Home() {
  const [openaiApiKey] = useLocalStorage(DB.OPENAI_API_KEY);
  const [openaiApiConfigOpen, setOpenaiApiConfigOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(0);
  const [progressLabel, setProgressLabel] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [tweets, setTweets] = useState([
    // 'Looking for a job as a CS grad? Here are a couple of ways to increase your chances in a tough market.',
    // 'For Fang companies, focus on a referral from your network. Tailor your resume and application for other companies.',
    // 'Entry-level in the tech market is tough, make sure to set yourself apart when applying to your dream job. Good luck!',
  ]);

  const handleFileSelection = async (event) => {
    setProgress(1);
    setProgressLabel('Processing video...');
    const fileInput = event.target.files[0];
    const data = await convertVideoInputToAudio(fileInput, ({ ratio }) =>
      setProgress(Math.round(ratio * 100 * 0.5) || 1),
    );

    setProgressLabel('Analyzing what you said...');
    let nextStepInterval = setInterval(() => {
      setProgress(progressRef.current + 1);
      if (progressRef.current >= 99) {
        clearInterval(nextStepInterval);
      }
    }, 1000);
    try {
      const transcript = await whisperUpload(data);

      setProgressLabel('Generating tweets...');
      const newTweets = await transcriptToTweets(transcript);
      clearInterval(nextStepInterval);
      setProgress(100);
      setTweets(newTweets);
      setProgressLabel('');
    } catch (e) {
      console.error(e);
      clearInterval(nextStepInterval);
      setProgressLabel('');
      setErrorMsg(
        'Something went wrong. Please reload the page and try again.',
      );
    }
  };

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  return (
    <Container
      maxWidth="sm"
      sx={(theme) => ({
        my: 10,
        [theme.breakpoints.down('sm')]: {
          my: 4,
        },
      })}
    >
      <OpenaiConfigModal
        onClose={() => {
          setOpenaiApiConfigOpen(false);
        }}
        open={openaiApiConfigOpen}
      />
      <Grid container flexDirection="column" spacing={2}>
        <Grid item>
          <GradientTypography variant="h2">Wisp</GradientTypography>
          <GradientTypography variant="h4">Video to Tweets</GradientTypography>
        </Grid>
        <Grid item>
          <GradientTypography variant="h5">
            Step 1: Set your OpenAI API key
          </GradientTypography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            size="small"
            label="OpenAI API Key"
            value={openaiApiKey}
            disabled
            type="password"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setOpenaiApiConfigOpen(true);
                  }}
                >
                  Change
                </Button>
              ),
            }}
          />
        </Grid>
        {Boolean(openaiApiKey) && (
          <>
            <Grid item>
              <GradientTypography variant="h5">
                Step 2: Pick a video
              </GradientTypography>
            </Grid>
            <FileInput onChange={handleFileSelection} />
          </>
        )}
        {Boolean(progress) && (
          <>
            <Grid item>
              <GradientTypography variant="h5">
                Step 3: Wait while we work
              </GradientTypography>
            </Grid>
            <UploadProgressBar progress={progress} label={progressLabel} />
            <Grid item sx={{ pt: '0 !important' }}>
              <Typography color="error">{errorMsg}</Typography>
            </Grid>
          </>
        )}
        {Boolean(tweets.length) && (
          <>
            <Grid item>
              <GradientTypography variant="h5">
                Step 4: See your Tweets!
              </GradientTypography>
            </Grid>
            <Grid item>
              <Tweets tweets={tweets} />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

const OpenaiConfigModal = ({ onClose, open }) => {
  const [openaiApiKey, setOpenaiApiKey] = useLocalStorage(DB.OPENAI_API_KEY);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        // if (reason === 'backdropClick') {
        //   return;
        // }
        onClose();
      }}
    >
      <DialogTitle>OpenAI Settings</DialogTitle>
      <DialogContent>
        <Typography>Settings are saved as soon as you change them</Typography>
        <Grid container>
          <TextField
            fullWidth
            margin="dense"
            size="small"
            label="OpenAI API Key"
            defaultValue={openaiApiKey}
            helperText="This is only saved in your browser, not on our servers."
            onChange={(event) => {
              setOpenaiApiKey(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

OpenaiConfigModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const FileInput = ({ onChange }) => {
  return (
    <Grid item sx={{ display: 'flex' }}>
      <Grid
        sx={{
          display: 'inline',
          border: '1px solid #ccc',
          borderRadius: '4px',
          p: '4px',
        }}
      >
        <input
          type="file"
          accept=".mp4, .avi, .mov"
          // accept="video/*"
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const Tweets = ({ tweets = [] }) => {
  return (
    <Grid container>
      {tweets.map((tweet, i) => (
        <Grid key={i} container justifyContent="center">
          <Grid
            item
            sx={{
              border: '1px solid #ccc',
              borderRadius: '12px',
            }}
          >
            <TweetCard
              author={{
                image: DefaultAvatar,
                username: 'username',
                name: 'You',
              }}
              time={new Date()}
              fitInsideContainer
              tweet={tweet}
              engagement={{
                replies: randomInt(0, 100),
                retweets: randomInt(0, 500),
                likes: randomInt(0, 2000),
              }}
            />
          </Grid>
          {i < tweets.length - 1 && (
            <Grid
              item
              sx={{
                backgroundColor: '#cfd9de',
                height: '40px',
                width: '6px',
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

Tweets.propTypes = {
  tweets: PropTypes.arrayOf(PropTypes.string),
};

function randomInt(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

const UploadProgressBar = ({ progress, label }) => {
  return (
    <Grid item>
      <LinearProgressWithLabel value={progress} />
      <Box>
        <Typography color="text.secondary">{label}</Typography>
      </Box>
    </Grid>
  );
};

UploadProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

function LinearProgressWithLabel(props) {
  const { value, label = 'Progress' } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: 1 }}>
        <Typography color="text.secondary">{label}</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ ml: 1, justifyContent: 'right', display: 'flex' }}>
        <Typography color="text.secondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
};
