import { Outlet } from 'react-router-dom';
// import NavigationBar from '../components/NavigationBar';

const AppLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default AppLayout;
