import axios from 'axios';

import { DB } from '../constants';

async function getApiKey() {
  return JSON.parse(localStorage.getItem(DB.OPENAI_API_KEY));
}

// XXX(Phong): `file` is a Blob converted to a File with:
// `const file = new File([blob], 'audio.mp3', { type: 'audio/mp3' });`
export async function audioTranscriptions(file) {
  const apiKey = await getApiKey();

  const formData = new FormData();
  formData.append('model', 'whisper-1');
  formData.append('file', file);
  const reqOpts = {
    method: 'POST',
    url: 'https://api.openai.com/v1/audio/transcriptions',
    headers: {
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
    data: formData,
  };
  const res = await axios(reqOpts);
  return res.data?.text;
}

export async function chatCompletions(prompt, data) {
  const apiKey = await getApiKey();

  const reqOpts = {
    method: 'POST',
    url: 'https://api.openai.com/v1/chat/completions',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
    data,
  };
  const res = await axios(reqOpts);
  return res.data?.choices?.[0]?.message?.content;
}
