import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';

// import Footer from '../components/Footer';

const CenterLayout = () => {
  return (
    <Container
      maxWidth="md"
      justifyContent="center"
      sx={{
        minHeight: '100vh',
        minWidth: '100%',
      }}
    >
      <Outlet />
      {/* <Footer /> */}
    </Container>
  );
};

export default CenterLayout;
