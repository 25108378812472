import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const NoPage = () => {
  const navigate = useNavigate();
  return (
    <Paper
      variant="outlined"
      sx={{ display: 'flex', justifycontent: 'center', padding: 5 }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography variant="h3" gutterBottom>
          Oops! Nothing Here
        </Typography>
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            navigate('/');
          }}
        >
          Go Home
        </Button>
      </Grid>
    </Paper>
  );
};

export default NoPage;
