import { audioTranscriptions, chatCompletions } from '../services/openai.js';

export async function whisperUpload(data) {
  const blob = new Blob([data]);
  const file = new File([blob], 'audio.mp3', { type: 'audio/mp3' });
  const transcript = await audioTranscriptions(file);
  return transcript;
}

export async function transcriptToTweets(transcript) {
  // transcript =
  //   "How do you find a job as a CS graduate right now? There are a couple of ways. Market's pretty bad right now and there are layoffs going all over the place, but it's not impossible to get a job as a new grad. Number one, if you're going the Fang route, they're too big to change their processes, so it's pretty typical, lead code, system design. The only difference here to boost your chances is that you need a referral, so reach out to your network, anyone you have as a friend in those companies. Number two, if you're going for any other company, what you need to do is you need to tailor your resume and application towards that company. A lot of people are still doing the shotgun method, keep doing that. At the end of the day, it's still a numbers game, but for the companies and the jobs that you really want, you've got to make sure that application is tailored. The tech market is no longer super hot. I mean, it's still good, but companies are no longer willing to take risks on entry levels, and so you've really got to find a way to set yourself apart. Good luck.";
  const prompt = `Convert this text for Twitter, one or more tweets, each tweet should not exceed 240 characters, structure your response as JSON: '["point 1","point 2","point 3"]'\n\nThis is the transcript:\n${transcript}`;
  const payload = {
    model: 'gpt-3.5-turbo',
    messages: [
      {
        role: 'system',
        content:
          "You are a computer program who summarizes long text into a list of tweets. You don't use hashtags.",
      },
      // {
      //   role: 'assistant',
      //   content: '{"data":["point 1","point 2","point 3"]}',
      // },
      { role: 'user', content: prompt },
    ],
  };

  const dataStr = await chatCompletions(prompt, payload);
  const data = JSON.parse(dataStr);
  return data;
}
