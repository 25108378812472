import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import CenterLayout from './layouts/Center';
import MainLayout from './layouts/Main';
import Home from './pages/Home';
import NoPage from './pages/NoPage';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/" element={<CenterLayout />}>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
