import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// XXX(Phong): returns a Uint8Array, a JS TypedArray
export async function convertVideoInputToAudio(file, progressCb) {
  const ffmpeg = createFFmpeg({
    log: true,
    progress: progressCb,
  });
  await ffmpeg.load();

  const srcFilepath = `${Date.now()}-${file.name}`;
  const dstFormat = 'mp3';
  const dstFilepath = `${file.name}.${dstFormat}`;

  ffmpeg.FS('writeFile', srcFilepath, await fetchFile(file));
  await ffmpeg.run('-i', srcFilepath, '-vn', dstFilepath);
  const data = ffmpeg.FS('readFile', dstFilepath);
  ffmpeg.exit();
  return data;
}
